.pagination-pill {
  width: 18px;
  height: 4px;
  border-radius: 30px;
  margin-right: 4px !important;
  margin-left: 0 !important;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  width: unset;
  bottom: unset;
  padding-left: 8px;
  padding-top: 14px;
}
